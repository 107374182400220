import React, { useEffect, useState, useCallback } from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import MobileMenu from './MobileMenu'
import DeskMenu from './DeskMenu'
import { useLocation } from '@reach/router'

export default function Header() {
  const isRoot = useLocation().pathname === '/'
  const [isScrolled, setIsScrolled] = useState(false)

  const handleScroll = useCallback(() => {
    const scrollTop = window.scrollY || document.documentElement.scrollTop
    setIsScrolled(scrollTop >= 300)
  }, [])

  useEffect(() => {
    let debounceTimeout: ReturnType<typeof setTimeout> | null = null
    const debouncedHandleScroll = () => {
      if (debounceTimeout !== null) {
        clearTimeout(debounceTimeout)
      }
      debounceTimeout = setTimeout(handleScroll, 50)
    }
    window.addEventListener('scroll', debouncedHandleScroll)
    return () => {
      window.removeEventListener('scroll', debouncedHandleScroll)
      if (debounceTimeout !== null) {
        clearTimeout(debounceTimeout)
      }
    }
  }, [handleScroll, isRoot])

  return (
    <header
      className={`fixed lg:absolute w-full max-w-[1600px] z-50 ${
        isRoot && !isScrolled ? 'bg-transparent' : 'bg-white border-b'
      }`}
    >
      <nav className='flex justify-between items-center py-4 px-2 sm:py-6 sm:px-4 lg:gap-12 xl:px-8 xl:py-12 2xl:px-20'>
        <Link to='/'>
          <StaticImage
            src='../../images/logos/logo.webp'
            alt='Main Logo'
            className='w-32 mx-auto sm:w-40 xl:w-56 2xl:w-72'
            placeholder='blurred'
            layout='constrained'
            loading='eager'
            quality={90}
          />

        </Link>
        <MobileMenu isRoot={isRoot} isScrolled={isScrolled} />
        <DeskMenu isRoot={isRoot} />
      </nav>
    </header>
  )
}
